import React, { useContext, useRef, useState } from "react";
import "../assets/style.scss";
import Swal from "sweetalert2";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { SongContext } from "../contexts/SongContext";

import { push } from "firebase/database";

const AddSong = ({ modalStatus, setModalStatus }) => {
  const { storage, db, loadSongs } = useContext(SongContext);
  const imageUploadRef = useRef(null);
  const songUploadRef = useRef(null);

  const [imagesrc, setSrc] = useState(null);
  const [imageFilename, setImageFileName] = useState(null);
  const [songFilename, setSongFileName] = useState(null);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [premium, SetPremium] = useState(false);
  const [category, SetCategory] = useState("meditation");
  const cats = ["meditation", "sync", "sleep"];

  const [imageToUpload, setImageToUpload] = useState(null);
  const [songToUpload, setSongToUpload] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onCoverChange = (event) => {
    if (event.target.files.length == 0) return;
    const file = event.target.files[0];
    if (!file.type.includes("image/")) {
      Swal.fire("Please select an image");
      return;
    }
    setImageFileName(file.name);
    setSrc(URL.createObjectURL(file));
    setImageToUpload(file);
  };
  const onSongChange = (event) => {
    if (event.target.files.length == 0) return;
    const file = event.target.files[0];
    if (!file.type.includes("audio/")) {
      Swal.fire("Please select an audio");
      return;
    }
    setSongFileName(file.name);
    setSongToUpload(file);
  };

  const uploadSong = async () => {
    if (!title || !description || !imageToUpload || !songToUpload) {
      Swal.fire("Please fill all details");
      return;
    }

    if (uploading) return;
    setUploading(true);

    const SongstorageRef = ref(storage, `songs/${songToUpload.name}`);
    const SonguploadTask = uploadBytesResumable(SongstorageRef, songToUpload);

    SonguploadTask.on(
      "state_changed",
      (snapshot) => {
        const percentage = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percentage);
      },
      (error) => {
        alert(error);
        setUploading(false);
        setProgress(0);
      },
      () => {
        setProgress(0);
        getDownloadURL(SonguploadTask.snapshot.ref).then(async (songsUrl) => {
          const CoverstorageRef = ref(storage, `cover/${imageToUpload.name}`);
          const CoveruploadTask = await uploadBytesResumable(
            CoverstorageRef,
            imageToUpload
          );
          const CoverUrl = await getDownloadURL(CoveruploadTask.ref);

          await push(db, {
            title,
            description,
            premium,
            songsUrl,
            CoverUrl,
            SongFileName: songToUpload.name,
            CoverFileName: imageToUpload.name,
            category,
          });

          setProgress(false);
          setUploading(false);
          await loadSongs();
          Swal.fire("Song Added Successfully");

          setModalStatus(false);
        });
      }
    );
  };

  return (
    <>
      <div
        id="google"
        className={"modaloverlay"}
        style={{
          display: modalStatus ? "flex" : "none",
        }}
      >
        <div className="modal">
          <a
            href="#close"
            className="close"
            onClick={() => setModalStatus(false)}
          >
            &times;
          </a>
          <div className="addSong">
            <h1> Add a song</h1>

            <input
              type="text"
              name="psw"
              className="inputBox"
              placeholder="Enter Title"
              onChange={(e) => setTitle(e.target.value)}
            />

            <input
              type="text"
              name="psw"
              className="inputBox"
              placeholder="Enter Description"
              onChange={(e) => setDescription(e.target.value)}
            />
            {imagesrc ? (
              <img
                src={imagesrc}
                onError={() => setSrc(null)}
                className="songCover"
              />
            ) : (
              ""
            )}

            <div className="d-flex mv-10">
              <input
                type="text"
                className="file-name"
                value={imageFilename}
                readOnly="readonly"
                accept="image/*"
                onClick={() => {
                  imageUploadRef.current.click();
                }}
              />

              <input
                type="file"
                ref={imageUploadRef}
                onChange={onCoverChange}
                accept="image/*"
                style={{ display: "none" }}
              />

              <input
                type="button"
                className="btn"
                value="Select Cover"
                onClick={() => {
                  imageUploadRef.current.click();
                }}
              />
            </div>

            <div className="d-flex mv-10">
              <input
                type="text"
                className="file-name"
                readOnly="readonly"
                value={songFilename}
                onClick={() => {
                  songUploadRef.current.click();
                }}
              />
              <input
                type="file"
                ref={songUploadRef}
                onChange={onSongChange}
                accept="audio/*"
                style={{ display: "none" }}
              />
              <input
                type="button"
                className="btn"
                value="Select Song"
                onClick={() => {
                  songUploadRef.current.click();
                }}
              />
            </div>
            <div>{progress ? <p>Progress {progress}%</p> : ""}</div>

            <div className="d-flex mv-10 check">
              Premium &nbsp;{" "}
              <input
                type="checkbox"
                checked={premium}
                onClick={() => SetPremium(!premium)}
              />
            </div>

            <div className="d-flex mv-10 ">
              <b>Category </b> &nbsp;
              {cats.map(function (each, i) {
                return (
                  <>
                    <input
                      type="radio"
                      name="category"
                      onClick={() => SetCategory(each)}
                      checked={each == category}
                    />
                    &nbsp; {each[0].toUpperCase() + each.slice(1).toLowerCase()}{" "}
                    &nbsp;
                  </>
                );
              })}
            </div>

            <button className="button" onClick={uploadSong}>
              Upload
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSong;
