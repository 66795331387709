import { createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, deleteObject, ref as storageRef } from "firebase/storage";
import { getDatabase, ref, onValue, remove } from "firebase/database";
import Swal from "sweetalert2";

const SongContext = createContext();
const SongState = (props) => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);

  const firebaseConfig = {
    apiKey: "AIzaSyBRDxZz-Kihqi_QShxTmMoPFPqOxT_Hf-g",
    authDomain: "tune-your-mind-45af9.firebaseapp.com",
    databaseURL: "https://tune-your-mind-45af9-default-rtdb.firebaseio.com",
    projectId: "tune-your-mind-45af9",
    storageBucket: "tune-your-mind-45af9.appspot.com",
    messagingSenderId: "658150650752",
    appId: "1:658150650752:web:7e87fb871dc6690b8c8765",
    measurementId: "G-80HD0NYWPE",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const database = getDatabase(app);
  const db = ref(database, "songs");

  const loadSongs = async () => {
    onValue(db, (snapshot) => {
      const rows = snapshot.val();
      let toSet = [];
      if (rows) {
        for (const key in rows) {
          if (rows.hasOwnProperty(key)) {
            let eachRow = rows[key];
            eachRow.id = key;
            eachRow.name = eachRow.title;
            eachRow.cover = eachRow.CoverUrl;
            eachRow.audio = eachRow.songsUrl;
            eachRow.artist = eachRow.description;
            toSet.push(eachRow);
          }
        }
      }
      // console.log(toSet);
      setSongs(toSet);
    });
  };

  useEffect(() => {
    loadSongs();
  }, []);

  useEffect(() => {
    if (songs.length > 0) {
      setCurrentSong(songs[0]);
    } else setCurrentSong(null);
  }, [songs]);

  const deleteSong = async (song) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteObject(storageRef(storage, "songs/" + song.SongFileName));
        await deleteObject(storageRef(storage, "cover/" + song.CoverFileName));
        await remove(ref(database, "songs/" + song.id));
        loadSongs();

        Swal.fire("Deleted!", "Your song has been deleted.", "success");
      }
    });
  };
  return (
    <SongContext.Provider
      value={{
        songs,
        setSongs,
        currentSong,
        setCurrentSong,
        storage,
        db,
        loadSongs,
        deleteSong,
      }}
    >
      {props.children}
    </SongContext.Provider>
  );
};

export default SongState;
export { SongContext };
