import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faPlus } from "@fortawesome/free-solid-svg-icons";

const Nav = ({ libraryStatus, setLibraryStatus, setModalStatus }) => {
  return (
    <NavContainer>
      <H1 libraryStatus={libraryStatus}>TYM</H1>
      <div>
        <Button onClick={() => setModalStatus(true)}>
          Add &nbsp;
          <FontAwesomeIcon icon={faPlus} />
        </Button>

        <Button onClick={() => setLibraryStatus(!libraryStatus)}>
          Library &nbsp;
          <FontAwesomeIcon icon={faMusic} />
        </Button>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.div`
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const H1 = styled.h1`
  transition: all 0.5s ease;

  @media screen and (max-width: 768px) {
    visibility: ${(p) => (p.libraryStatus ? "hidden" : "visible")};
    opacity: ${(p) => (p.libraryStatus ? "0" : "100")};
    transition: all 0.5s ease;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  border: 2px solid rgb(65, 65, 65);
  padding: 0.5rem;
  transition: all 0.3s ease;
  margin: 0px 3px;
  &:hover {
    background: rgb(65, 65, 65);
    color: white;
  }
`;

export default Nav;
