import React from "react";
import "../assets/style.scss";
const NoSong = ({ setModalStatus }) => {
  return (
    <>
      <div className="noSong">
        <h1>No Song Found</h1>
        <button onClick={() => setModalStatus(true)}>Add a song</button>
      </div>
    </>
  );
};

export default NoSong;
