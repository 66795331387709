import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import "./App.css";

// Import components
import Player from "./components/Player";
import Song from "./components/Song";
import Library from "./components/Library";
import Nav from "./components/Nav";

import AddSong from "./components/AddSong";
import { SongContext } from "./contexts/SongContext";
import NoSong from "./components/NoSong";

const App = () => {
  // Ref
  const audioRef = useRef(null);
  const { songs, currentSong, setCurrentSong } = useContext(SongContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [libraryStatus, setLibraryStatus] = useState(false);
  const [modalStatus, setmodalStatus] = useState(false);
  const [songInfo, setSongInfo] = useState({
    currentTime: 0,
    duration: 0,
  });

  // Functions
  const updateTimeHandler = (e) => {
    const currentTime = e.target.currentTime;
    const duration = e.target.duration;
    setSongInfo({ ...songInfo, currentTime, duration });
  };

  const songEndHandler = async () => {
    let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
    let nextSong = songs[(currentIndex + 1) % songs.length];
    await setCurrentSong(nextSong);

    if (isPlaying) {
      audioRef.current.play();
    }
  };

  return (
    <AppContainer libraryStatus={libraryStatus}>
      <Nav
        libraryStatus={libraryStatus}
        setLibraryStatus={setLibraryStatus}
        setModalStatus={setmodalStatus}
      />
      {currentSong ? (
        <>
          <Song currentSong={currentSong} />
          <Player
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            currentSong={currentSong}
            setCurrentSong={setCurrentSong}
            audioRef={audioRef}
            songInfo={songInfo}
            setSongInfo={setSongInfo}
            songs={songs}
          />
          <Library
            songs={songs}
            setCurrentSong={setCurrentSong}
            audioRef={audioRef}
            isPlaying={isPlaying}
            libraryStatus={libraryStatus}
          />

          <audio
            onLoadedMetadata={updateTimeHandler}
            onTimeUpdate={updateTimeHandler}
            onEnded={songEndHandler}
            ref={audioRef}
            src={currentSong.audio}
          />
        </>
      ) : (
        <NoSong setModalStatus={setmodalStatus} />
      )}

      {modalStatus ? (
        <AddSong modalStatus={modalStatus} setModalStatus={setmodalStatus} />
      ) : (
        ""
      )}
    </AppContainer>
  );
};

const AppContainer = styled.div`
  transition: all 0.5s ease;
  margin-left: ${(p) => (p.libraryStatus ? "20rem" : "0")};
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export default App;
